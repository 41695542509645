body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Assistant';
  src: local('Assistant-Medium'),
    url(/static/media/Assistant-Medium.ttf) format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Assistant';
  src: local('Assistant-SemiBold'),
    url(/static/media/Assistant-SemiBold.ttf) format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Assistant';
  src: local('Assistant-Bold'),
    url(/static/media/Assistant-Bold.ttf) format('truetype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Assistant';
  src: local('Assistant-Regular'),
    url(/static/media/Assistant-Regular.ttf) format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Assistant';
  src: local('Assistant-ExtraLight'),
    url(/static/media/Assistant-ExtraLight.ttf) format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Assistant-ExtraBold';
  src: local('Assistant-ExtraBold'),
    url(/static/media/Assistant-ExtraBold.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow-y: scroll;
}

/* Remove captcha banner */
.grecaptcha-badge {
  visibility: hidden;
}

